<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Rutas</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tep</li>
                  <li class="breadcrumb-item active">Nacionales</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">Rutas</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-10">
                    <h5>Rutas</h5>
                  </div>
                  <div class="col-md-2">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="create()"
                        v-if="$store.getters.can('tep.rutas.create')"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#tep_rutas_export"
                        v-if="$store.getters.can('tep.rutas.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="users"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>
                        ID Ruta
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.id_ruta"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>Kilometros Pavimentados</th>
                      <th>Kilometros Destapados</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in rutas.data" :key="item.id">
                      <td>{{ item.id }}</td>
                      <td>{{ item.nombre }}</td>
                      <td>{{ item.km_pavimentados }}</td>
                      <td>{{ item.km_destapados }}</td>
                      <td style="width: 50px">
                        <div class="btn-group float-left">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#modal-form-asignacion-tep-postulacion-nacional"
                            style="cursor: pointer"
                            v-if="$store.getters.can('tep.rutas.edit')"
                            @click="edit(item)"
                            :disabled="item.postulacion_nacional != null"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="
                              $store.getters.can('tif.rutas.delete')
                            "
                            @click="destroy(item)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="rutas.total">
                  <p>
                    Mostrando del <b>{{ rutas.from }}</b> al
                    <b>{{ rutas.to }}</b> de un total:
                    <b>{{ rutas.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="rutas"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <rutaExport ref="rutaExport" />
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import pagination from "laravel-vue-pagination";
import rutaExport from "./rutaExport";

export default {
  name: "rutaIndex",
  components: {
    Loading,
    pagination,
    rutaExport,
  },
  data() {
    return {
      cargando: false,
      rutas: {},
      filtros: {
        id_ruta: null,
        nombre: null,
      },
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/tep/Ruta?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.rutas = response.data;
          this.cargando = false;
        });
    },

    create() {
      return this.$router.push({
        name: "/Tep/RutaForm",
        params: { accion: "Crear", id: null },
      });
    },

    edit(ruta) {
      return this.$router.push({
        name: "/Tep/RutaForm",
        params: { accion: "Editar", data_edit: ruta, id: ruta.id },
      });
    },

    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },

    destroy(item) {
      this.$swal({
        title: "Esta seguro de eliminar la ruta: " + item.nombre + "?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios.delete("/api/tep/rutas/" + item.id).then(() => {
            this.cargando = false;
            this.$swal({
              icon: "success",
              title: "Se eliminó la ruta exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.getIndex();
          });
        }
      });
    },
  },
  mounted() {
    this.getIndex();
    this.getMsg();
  },
};
</script>
