<template>
  <div class="hold-transition">
    <div
      class="modal fade"
      id="tep_rutas_export"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Generar listado Terceros</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-0">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Columnas"
                  data-toggle="tab"
                  href="#Columnas"
                  >Columnas</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Columnas">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-11">
                      <div class="form-group">
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="id_ruta1"
                              v-model="form.id_ruta"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="id_ruta1">Id</label>
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="nombre1"
                              v-model="form.nombre"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="nombre1">Nombre</label>
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="km_pavimentados1"
                              v-model="form.km_pavimentados"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="km_pavimentados1">Kilómetros Pavimentados</label>
                          </div>
                        </div>
                        <div>
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              id="km_destapados"
                              v-model="form.km_destapados"
                              class="custom-control-input"
                            />
                            <label class="custom-control-label" for="km_destapados">Kilómetros Destapados</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="tipo_documento" class="col-md-5">Id Ruta</label>
                    <input
                      type="number"
                      class="form-control form-control-sm col-md-7"
                      v-model="filtros.id_ruta"
                    />
                  </div>
                  <div class="form-group row">
                    <label for="nombre" class="col-md-5">Nombre</label>
                    <input
                      type="text"
                      id="nombre"
                      v-model="filtros.nombre"
                      class="form-control form-control-sm col-md-7"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                v-if="$store.getters.can('tep.rutas.export')"
                @click="generarListadoExcel()"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "rutaExport",
  components: {},
  data() {
    return {
      form: {
        id_ruta: true,
        nombre: true,
        km_pavimentados: true,
        km_destapados: true,
      },
      filtros: {
        id_ruta: null,
        nombre: null,
      },
    };
  },

  methods: {
    generarListadoExcel() {
      this.$parent.cargando = true;
      axios({
        method: "POST",
        url: "/api/tep/rutas/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          this.$parent.cargando = false;
          let data = response.data;
          if (data.message) {
            this.$swal({
              icon: "success",
              title: data.message,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            location.href = data.url;
          } else {
            this.$swal({
              icon: "error",
              title: data.error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },
  },
};
</script>
